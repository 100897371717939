// Future optimization notes
// import * as Matter from 'matter-js';
// const { Engine, Render, World, Bodies } = Matter;

// - rendering jitter - https://threejs.org/examples/webgl_worker_offscreencanvas.html
// - send deltas
// - send compressed deltas
// - send compressed deltas of each type of particle
// - send only input deltas and have client compute the rest (deterministic)


import {decode, encode} from "@thi.ng/rle-pack";

import PartySocket from "partysocket";
import {
  ALL_TYPES,
  CellType,
  type CellUpdate,
  type ClickActionMessage,
  type DragMouseActionMessage,
  drawLine,
  EMPTY,
  getCellColor,
  GRID_HEIGHT,
  GRID_WIDTH,
  initGrid,
  MouseActionType,
  OBSTACLE,
  PREDICT_MULTIPLE, SERVER_PREDICT_WITHIN_FRAME,
  SAND,
  SERVER_GRID_UPDATE_FREQUENCY,
  updateGrid,
  WATER,
} from "./sharedSim";
// @ts-ignore
import Stats from 'stats.js'
import GUI from 'lil-gui';
// import {TextureLoader} from "three";
import * as THREE from 'three';
// import {Vector2} from "three/src/math/Vector2";


// import { AsciiEffect }  from "postprocessing";



// bad-tv-shader
// import { BloomEffect, EffectComposer, EffectPass, RenderPass, ShaderPass,
//   GlitchEffect, BlendFunction, KernelSize, BlendMode,
//   NoiseEffect, VignetteEffect, ChromaticAberrationEffect,
//    KernelSizeString, BlendFunctionString, BlendModeString } from "postprocessing";
//
// import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
// import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js';



const scene = new THREE.Scene();

const aspectRatio = window.innerWidth / window.innerHeight;
const cameraViewHeight = 20;
const cameraViewWidth = cameraViewHeight * aspectRatio;

interface Point2D {
  x: number;
  y: number;
}

let lastPosition: Point2D | null = null;
let lastPositionHover: Point2D | null = null;

const camera = new THREE.OrthographicCamera(
  -cameraViewWidth / 2,
  cameraViewWidth / 2,
  cameraViewHeight / 2,
  -cameraViewHeight / 2,
  0,
  100
);
camera.position.z = 30;  // Adjust this to move the camera further out if required

const stats = new Stats();
// stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
// hide
// document.body.appendChild(stats.dom);
// stats.dom.style.display = 'none';

// const gui = new GUI({
//   closeFolders: true,
//   title: 'Stats',
//   // start collapsed
//   open: false,
//
// });
const guiControls = {
  overwrite: false,
};
const brushSettings = {
  brushSize: 6
};

// gui.hide();

// const dropArea = document.getElementById('drop-area');

// dropArea.addEventListener('dragover', (event) => {
//   event.preventDefault();
//   event.dataTransfer.dropEffect = 'copy';
// });
//
// dropArea.addEventListener('drop', (event) => {
//   event.preventDefault();
//   const file = event.dataTransfer.files[0];
//   processFile(file);
// });
// const pasteArea = document.getElementById('paste-area');
//
// pasteArea.addEventListener('paste', (event) => {
//   event.preventDefault();
//   const items = event.clipboardData.items;
//   for (const item of items) {
//     if (item.type.startsWith('image')) {
//       const file = item.getAsFile();
//       processFile(file);
//       break;
//     }
//   }
// });

// function loadCanvasIntoGrid(canvas) {
//   const resizedCanvas = document.createElement('canvas');
//   resizedCanvas.width = GRID_WIDTH;
//   resizedCanvas.height = GRID_HEIGHT;
//   const resizedCtx = resizedCanvas.getContext('2d', { willReadFrequently: true });
//   resizedCtx.imageSmoothingEnabled = false;  // Disable image smoothing
//   resizedCtx.drawImage(canvas, 0, 0, GRID_WIDTH, GRID_HEIGHT);
//
//   let src = cv.imread(resizedCanvas);
//   let dst = new cv.Mat();
//   cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY);
//   cv.adaptiveThreshold(src, dst, 255, cv.ADAPTIVE_THRESH_MEAN_C, cv.THRESH_BINARY, 11, 2);
//   cv.imshow(resizedCanvas, dst);
//   src.delete();
//   dst.delete();
//
//   const resizedImageData = resizedCtx.getImageData(0, 0, resizedCanvas.width, resizedCanvas.height).data;
//   // Save out the image
//   resizedCtx.save();
//
//   // Convert the image data back into the grid format and load it into the simulation
//   const grid = imageToGrid(resizedImageData, resizedCanvas.width, resizedCanvas.height);
//   loadGridIntoSimulation(grid);
// }
//
// function loadImageIntoGrid(image: HTMLImageElement) {
//   const canvas = document.createElement('canvas');
//   console.log(image.width, image.height);
//   canvas.width = image.width;
//   canvas.height = image.height;
//   const ctx = canvas.getContext('2d');
//   ctx.drawImage(image, 0, 0);
//   loadCanvasIntoGrid(canvas);
// }

// function processFile(file) {
//   if (!file) return;
//
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     const dataUrl = event.target.result;
//     const image = new Image();
//     image.onload = () => {
//       loadImageIntoGrid(image);
//     };
//     image.src = dataUrl;
//   };
//   reader.readAsDataURL(file);
// }
//
// document.getElementById('fileInput').addEventListener('change', function(event) {
//   const file = event.target.files[0];
//   if (!file) return;
//
//   const reader = new FileReader();
//   reader.onload = function(event) {
//     const dataUrl = event.target.result;
//     const image = new Image();
//     image.onload = function() {
//       loadImageIntoGrid(image);
//     };
//     image.src = dataUrl;
//   };
//   reader.readAsDataURL(file);
// });
function loadGridIntoSimulation(grid) {
  // Load the grid into your simulation
  latestGridModel = grid;
  // Temporarily - disconnect to continue since server not syncing this atm
  // Send the grid to the server
  socket._ws.close()
}
function imageToGrid(imageData, width, height) {
  const grid = initGrid(); // Initialize your grid here
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const color = (imageData[index] << 16) | (imageData[index + 1] << 8) | imageData[index + 2];
      const cellType = colorToCellType(color);
      grid[index] = cellType;
    }
  }
  return grid;
}

function colorToCellType(color) {
  const colors = {
    0xAAAAAA: CellType.EMPTY,
    0xFFFF00: CellType.SAND,
    0x000000: CellType.WALL,
    0x0000FF: CellType.WATER,
  };

  let closestColor = null;
  let minDistance = Infinity;

  for (const [key, value] of Object.entries(colors)) {
    const distance = colorDistance(color, parseInt(key));
    if (distance < minDistance) {
      minDistance = distance;
      closestColor = value;
    }
  }

  return closestColor;
}

function colorDistance(color1, color2) {
  const r1 = (color1 >> 16) & 0xff;
  const g1 = (color1 >> 8) & 0xff;
  const b1 = color1 & 0xff;

  const r2 = (color2 >> 16) & 0xff;
  const g2 = (color2 >> 8) & 0xff;
  const b2 = color2 & 0xff;

  const dr = r2 - r1;
  const dg = g2 - g1;
  const db = b2 - b1;

  return dr * dr + dg * dg + db * db;
}



// gui.add(brushSettings, 'brushSize', 1, 50).name('Brush Size').step(1);
//
// const lilStats = {
//   frameCount: 0,
//   maxMessageDelta: 0,
//   averageMessageDelta: 0,
//   messageDelta: 0,
// };
// gui.add(lilStats, 'frameCount').name('Frame Counter').listen();
// gui.add(lilStats, 'messageDelta').name('Last Message Delay').listen();
// gui.add(lilStats, 'maxMessageDelta').name('Max Message Delay').listen();
// gui.add(lilStats, 'averageMessageDelta').name('Avg Message Delay').listen().decimals(0);
// gui.add(guiControls, 'overwrite').name('Overwrite');

let latestGridModel = initGrid();
const localGridModels = [latestGridModel];
let latestDataTexture = new THREE.DataTexture(latestGridModel, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat);
latestDataTexture.needsUpdate = true;
const dataTextures: THREE.DataTexture[] = [latestDataTexture];


let currentTool = SAND; // Default tool
const tools = {
  [EMPTY]: {
    name:
        'Air'
  },
  [OBSTACLE]: {
    name:
        'Obstacle'
  },
  [SAND]: {
    name:
        'Sand'
  },
  [WATER]: {
    name:
        'Water'
  },
};
const toolDisplay = document.getElementById('currentTool');
updateToolDisplay();

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);

renderer.domElement.addEventListener('wheel', (event) => {
  if (event.shiftKey) {
    // Scroll up to increase size, down to decrease size
    if (event.deltaY < 0) {
      // Increase brush size, but not more than the maximum set in the GUI
      brushSettings.brushSize = Math.min(brushSettings.brushSize + 1, 50);
    } else {
      // Decrease brush size, but not less than the minimum
      brushSettings.brushSize = Math.max(brushSettings.brushSize - 1, 1);
    }

    // Update the GUI to reflect the new value
    // gui.updateDisplay();
    // event.preventDefault();
  } else {
      // Existing zoom logic
    // Set the zoom speed
    const zoomSpeed = 0.1;

    // Calculate the new zoom level
    let newZoom = camera.zoom;
    if (event.deltaY < 0) {
      newZoom += zoomSpeed * (2 / newZoom) ^ 1.1;
    } else {
      newZoom -= zoomSpeed * (2 / newZoom) ^ 1.1;
    }

    // Update the camera zoom and update the projection matrix
    camera.zoom = Math.max(0.95, Math.min(50, newZoom));
    camera.updateProjectionMatrix();

    // Update the position of the camera to zoom towards the mouse position
    const mousePos = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    );

    const vector = new THREE.Vector3(mousePos.x, mousePos.y, 0.5);
    vector.unproject(camera);

    const dir = vector.sub(camera.position).normalize();
    const distance = -camera.position.z / dir.z;

    const newPos = (event.deltaY < 0)
      ? camera.position.clone().add(dir.multiplyScalar(distance))
      : camera.position.clone(); // no change in position when zooming out

    // Constrain the camera's position to the bounds of the scene
    const halfWidth = canvasWidth / 2;
    const halfHeight = canvasHeight / 2;

    newPos.x = Math.max(-halfWidth, Math.min(halfWidth, newPos.x));
    newPos.y = Math.max(-halfHeight, Math.min(halfHeight, newPos.y));

    camera.position.lerp(newPos, 0.2);
  }
}, {passive: true});


window.addEventListener('resize', () => {
  const aspectRatio = window.innerWidth / window.innerHeight;
  const cameraViewHeight = 20; // This can be your choice of the height of the camera's view
  const cameraViewWidth = cameraViewHeight * aspectRatio;

  camera.left = -cameraViewWidth / 2;
  camera.right = cameraViewWidth / 2;
  camera.top = cameraViewHeight / 2;
  camera.bottom = -cameraViewHeight / 2;

  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});

document.addEventListener('touchmove', function (e) {
  e.preventDefault();
}, {passive: false});
window.addEventListener('resize', function () {
  document.documentElement.style.height = window.innerHeight + 'px';
});

renderer.domElement.style.zIndex = "-11";
renderer.domElement.style.position = "absolute";
renderer.domElement.style.imageRendering = "pixelated";

// @ts-ignore
document.querySelector('#gameCanvas').appendChild(renderer.domElement);
// document.body.appendChild();

// Sort of a Router
// Read the url params
// const sandbox = new URLSearchParams(window.location.search).get('sandbox') || "default;
// Use URL routing, sand.graphics/sand/room-name
  // /new for new room (obscure but could possibly be found in future)
  // /tab for new tab page/extension
  // /watch room playlists
  // /menu for the menu room (realtime events, code DJs, new multiplayer game tests, live videos, art, etc.)
  // /sand/OIFJFOIF987 - shortlink for a sand
  // /local for read only/offline (CDN) mode
  // /rewind-support - take snapshots of room every second and allow rewind / FF-gifs (parallel interpolation rehydrate options)

// emojis mixed in with shortcode
const parsedUrl = new URL(window.location.href);

function randomId(number: number) {
  let result = '';
  let characters = '23579CDFGHJKMNPRTVWXY';
  // Add all emojis to the list of characters
  // for (let i = 0; i < 1000; i++) {
  //   characters += String.fromCodePoint(0x1F000 + i);
  // }
  const charactersLength = characters.length;
  for (let i = 0; i < number; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// let room = `sand-painting-${parsedUrl.pathname.split('/').pop() || randomId(6)}`;
// Grab from after #
let room = parsedUrl.hash.split('#/sand/')[1] || randomId(12);

// push sand to URL bar
window.history.replaceState({}, '', `#/sand/${room}`);

let socket = new PartySocket({
  // @ts-ignore
  host: PARTYKIT_HOST, // automatically defined
  room: room,
  // guid or public
  // save state restore or new state

});

// Multiple socket sliding
const sockets = [];

window.addEventListener('hashchange', () => {
  // Grab the room name from the URL
  // console.log("Hash changed");
  const parsedUrl = new URL(window.location.href);
  const newRoom = parsedUrl.hash.split('#/room/')[1];

  // Update the room in the socket connection
  if (socket.room !== newRoom) {
    // socket._ws.close();

    socket = new PartySocket({
        // @ts-ignore
        host: PARTYKIT_HOST, // automatically defined
        room: newRoom,
    });
    bindSocket();

    // You may need to disconnect and reconnect the socket here
    // socket.connect();
  }
}, false);

let debugLastUpdateTime = 0;

function updateLastMessageDebugTime() {
  if (debugLastUpdateTime > 0) {
    const delta = +Date.now() - debugLastUpdateTime;
    // lilStats.messageDelta = delta;
    // lilStats.averageMessageDelta = (lilStats.averageMessageDelta * lilStats.frameCount + delta) / (lilStats.frameCount + 1);
    // lilStats.maxMessageDelta = Math.max(lilStats.maxMessageDelta, delta);
  }
  debugLastUpdateTime = +Date.now();
}

function bindSocket() {
  socket.onmessage = async function (event) {
    updateLastMessageDebugTime();
    const buffer = await event.data.arrayBuffer();
    const array = new Uint8Array(buffer);
    const flatGridWithCount = decode(array);
    const frameCountDecoded = (flatGridWithCount[flatGridWithCount.length - 4] << 24) |
        (flatGridWithCount[flatGridWithCount.length - 3] << 16) |
        (flatGridWithCount[flatGridWithCount.length - 2] << 8) |
        flatGridWithCount[flatGridWithCount.length - 1];
    // console.log(`Received frame ${frameCountDecoded}`);
    latestServerFrame = frameCountDecoded;
    clientFrame = latestServerFrame;
    // @ts-ignore
    // copy into latestGridModel buffer
    // @ts-ignore
    latestGridModel.set(flatGridWithCount.slice(0, Math.min(flatGridWithCount.length - 4, latestGridModel.length)));
  };
  sockets.push(socket);
  const initialData = new Uint8Array(GRID_WIDTH * GRID_HEIGHT * 3);
  const nextGrid = initialData.slice();
  const dataTexture = new THREE.DataTexture(nextGrid, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat);
  dataTexture.needsUpdate = true;
  dataTextures.push(dataTexture);
  localGridModels.push(nextGrid);
  latestDataTexture = dataTexture;

  const material = new THREE.MeshBasicMaterial({
    map: dataTexture,
    transparent: true,
    opacity: 1 / sockets.length,
  });
  const planeGeometry = new THREE.PlaneGeometry(canvasWidth, canvasHeight);
  const planeMesh = new THREE.Mesh(planeGeometry, material);
  planeMesh.scale.y = -1;
  // semi transparent and offset so you can see all 3 layers
    planeMesh.position.z = 0.1 * sockets.length;
  planeMesh.position.x = sockets.length;
  // todo: alpha or blend mode etc.
  planeMesh.material.opacity = 0.5;
  scene.add(planeMesh);
}

bindSocket();

// @ts-ignore
document.getElementById('currentTool').addEventListener('click', () => {
  currentTool = (currentTool + 1) % ALL_TYPES.length;
  updateToolDisplay();
});

document.addEventListener('keydown', (event) => {
  switch(event.key) {
    case '1':
      currentTool = SAND;
      break;
    case '2':
      currentTool = EMPTY;
      break;
    case '3':
      currentTool = OBSTACLE;
      break;
    case '4':
      currentTool = WATER;
      break;
    case '0':
      // if (gui._hidden) {
        // gui.show();
        // stats.dom.style.display = 'initial';
      // } else {
        // stats.dom.style.display = 'none';
        // gui.hide();
      // }
      // console.log("Showing")
      break;
    default: return;
  }
  // updateToolDisplay();
  event.preventDefault(); // prevent the default action
});

function updateToolDisplay() {
  lastPosition = null;
  // @ts-ignore
  toolDisplay.textContent = `Current Tool: ${tools[currentTool].name}`;
}

window.addEventListener('blur', function() {
  lastPosition = null;
});

// renderer.domElement.addEventListener('click', createSand, { passive: true });

let dragging = false;

let socketIDNext = 0;
function sendMouseClick(gridPos: { gridX: number; gridY: number }) {
  const mouseAction: ClickActionMessage = {
    actionType: MouseActionType.CLICK,
    x: gridPos.gridX,
    y: gridPos.gridY,
    cellType: currentTool,
    frame: clientFrame,
    brushSize: brushSettings.brushSize
  };
  const mouseActionSerialized = JSON.stringify(mouseAction);
  sockets[socketIDNext].send(mouseActionSerialized);
  socketIDNext = (socketIDNext + 1) % sockets.length;
  // .forEach((socket) => {
  // });
  lastPosition = { x: gridPos.gridX, y: gridPos.gridY };
}


let dragCounter = 0;

function handleMouseDrag(gridPos: { gridX: number; gridY: number }) {
  if (!lastPosition) {
    return;
  }

  let toolToUse = currentTool;
  if (currentTool === SAND || currentTool === WATER ||
    currentTool === CellType.SAND_DENSITY_1 || currentTool === CellType.SAND_DENSITY_2 ||
    currentTool === CellType.SAND_DENSITY_3 || currentTool === CellType.SAND_DENSITY_4 ||
    currentTool === CellType.SAND_DENSITY_5
  ) {
    // Pick random of the sand densities
    if (dragCounter % 2 === 1) {
      toolToUse = CellType.SAND_DENSITY_1 + Math.floor(Math.random() * 5);
    } else {
        toolToUse = CellType.WATER;
    }

  }

  const mouseAction: DragMouseActionMessage = {
    actionType: MouseActionType.DRAG,
    x: gridPos.gridX,
    y: gridPos.gridY,
    lastX: lastPosition.x,
    lastY: lastPosition.y,
    cellType: toolToUse,
    frame: clientFrame,
    brushSize: brushSettings.brushSize
  };
  lastPosition = { x: gridPos.gridX, y: gridPos.gridY };
  const mouseActionSerialized = JSON.stringify(mouseAction);
  socket.send(mouseActionSerialized);
  drawLine(latestGridModel, mouseAction.x, mouseAction.y, mouseAction.lastX, mouseAction.lastY, mouseAction.brushSize, mouseAction.cellType);

  dragCounter++; // Increment the counter
}

renderer.domElement.addEventListener('mousedown', (event: MouseEvent) => {
  dragging = true;
  const gridPos = viewportPosToGrid(event.clientX, event.clientY);
  sendMouseClick(gridPos);

}, { passive: true });


renderer.domElement.addEventListener('mousemove', (event:MouseEvent) => {
  const {gridX, gridY} = viewportPosToGrid(event.clientX, event.clientY);
  lastPositionHover = { x: gridX, y: gridY };

  if (dragging) {
    handleMouseDrag(viewportPosToGrid(event.clientX, event.clientY));
    // console.log("Dragging...");
  } else {
    // Visualize the brush shape on hover
    // visualize others' here eventually maybe
  }

}, {passive: true});

renderer.domElement.addEventListener('mouseup', () => {
  endDragging();
}, {passive: true});

renderer.domElement.addEventListener('touchstart', (event:TouchEvent) => {
  dragging = true;
  const touch = event.touches[0];
  sendMouseClick(viewportPosToGrid(touch.clientX, touch.clientY));
}, {passive: true});

renderer.domElement.addEventListener('touchmove', (event:TouchEvent) => {
  if (dragging) {
    const touch = event.touches[0];
    handleMouseDrag(viewportPosToGrid(touch.clientX, touch.clientY));
  }
}, {passive: true});

function endDragging() {
  // console.log("Ending Dragging...");
  dragging = false;
  lastPosition = null;
}

renderer.domElement.addEventListener('touchend', () => {
  endDragging();
}, {passive: true});

function viewportPosToGrid(viewportX: number, viewportY: number) {
  const rect = renderer.domElement.getBoundingClientRect();

  // Normalized device coordinates
  const x = ((viewportX - rect.left) / renderer.domElement.clientWidth) * 2 - 1;
  const y = -((viewportY - rect.top) / renderer.domElement.clientHeight) * 2 + 1;

  const mousePos = new THREE.Vector3(x, y, 0.5);
  mousePos.unproject(camera);

  // Convert from world coordinates to grid coordinates
  const gridX = Math.floor((mousePos.x + canvasWidth / 2) / CELL_WIDTH);
  const gridY = Math.floor((canvasHeight / 2 - mousePos.y) / CELL_HEIGHT);
  return {gridX, gridY};
}

let drawingsThisFrame = 0;

// @ts-ignore
window.grid = latestGridModel;

let pendingUpdates: PendingUpdate[] = [];
type PendingUpdate = {
    update: CellUpdate,
    clientFrame: number,
    state: PendingUpdateState
}
enum PendingUpdateState {
  PENDING,
  DISPATCHED,
  REJECTED
}

let clientFrame = -1;  // Track the client's frame number

function placeDot(x:number, y:number) {
  // Allow overwrite for walls
  if (!guiControls.overwrite && latestGridModel[y * GRID_WIDTH + x] !== EMPTY && currentTool !== EMPTY && currentTool !== OBSTACLE) {
    return;
  }

  if (latestGridModel[y * GRID_WIDTH + x] === currentTool) {
    return;
  }

  ++drawingsThisFrame;
  const update = {
    x: x,
    y: y,
    cellType: currentTool
  } as CellUpdate;
  createLocalUpdate(update, clientFrame);
  pendingUpdates.push({
    update: update,
    clientFrame: clientFrame,
    state: PendingUpdateState.PENDING
  });
}


const localUpdates: {
  update: CellUpdate,
  clientFrame: number
}[] = [];

function createLocalUpdate(update: CellUpdate, clientFrame: number) {
  localUpdates.push({
    update: update,
    clientFrame: clientFrame
  });
  latestGridModel[update.y * GRID_WIDTH + update.x] = update.cellType;
}

// This function will draw a circle around the given (cx, cy) with the given radius.
function drawCircle(cx:number, cy:number, radius:number) {
  if (radius === 1) {
    placeDot(cx, cy);
    return;
  }
  for (let y = Math.max(0, cy - radius); y <= Math.min(GRID_HEIGHT - 1, cy + radius); y++) {
    for (let x = Math.max(0, cx - radius); x <= Math.min(GRID_WIDTH - 1, cx + radius); x++) {
      const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
      if (distance <= radius) {
        placeDot(x, y);
      }
    }
  }
}


renderer.domElement.addEventListener('mouseup', () => {
  dragging = false;
  lastPosition = null;  // Reset the last position when the mouse is released
}, {passive: true});


const canvasWidth = 20;
const canvasHeight = 20;

const CELL_WIDTH = canvasWidth / GRID_WIDTH;
const CELL_HEIGHT = canvasHeight / GRID_HEIGHT;

// Initialize the dataTexture with a correctly sized array.
const initialData = new Uint8Array(GRID_WIDTH * GRID_HEIGHT * 3);
const dataTexture = new THREE.DataTexture(initialData, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat);
dataTexture.needsUpdate = true;

const material = new THREE.MeshBasicMaterial({ map: dataTexture });
const planeGeometry = new THREE.PlaneGeometry(canvasWidth, canvasHeight);
const planeMesh = new THREE.Mesh(planeGeometry, material);
planeMesh.scale.y = -1;
scene.add(planeMesh);

let latestServerFrame = -1;

function updateData(update: CellUpdate, data: Uint8ClampedArray) {
  const {x, y} = update;
  const idx = (y * GRID_WIDTH + x) * 4;
  const color: number = getCellColor(update.cellType);
  data[idx] = (color >> 16) & 255;
  data[idx + 1] = (color >> 8) & 255;
  data[idx + 2] = color & 255;
  data[idx + 3] = 255; // Alpha, you can adjust this if needed
}

function gridToTexture(gridModel: Uint8Array = latestGridModel) {
  const data = new Uint8ClampedArray(GRID_WIDTH * GRID_HEIGHT * 4);
  for (let y = 0; y < GRID_HEIGHT; y++) {
    for (let x = 0; x < GRID_WIDTH; x++) {
      updateData({x, y, cellType: latestGridModel[y * GRID_WIDTH + x]}, data);
      // Alpha Blend
      // Get local model updates
      // localGridModels.forEach((grid) => {
      //   const cellType = grid[y * GRID_WIDTH + x];
      //   if (cellType !== -1) {
      //     updateData({x, y, cellType}, data);
      //   }
      // });
    }
  }

  for (let i = pendingUpdates.length - 1; i >= 0; i--) {
    const {update, clientFrame: expiryFrame, state} = pendingUpdates[i];
    // console.log(`Pending update ${i} with state ${state}`);
    // console.log(`Latest client ${expiryFrame} server frame: ${latestServerFrame}`);
    if (expiryFrame + PREDICT_MULTIPLE * 4 < latestServerFrame) {
      pendingUpdates.splice(i, 1); // expired - remove from list
      continue;
    }
    updateData(update, data);
  }

  // Render brush preview
  if (lastPositionHover) {
    // update when drawing with mouse

    const {x, y} = lastPositionHover;
    if (brushSettings.brushSize === 1) {
      updateData({x, y, cellType: currentTool}, data);
    } else {
      for (let yDots = Math.max(0, y - brushSettings.brushSize); yDots <= Math.min(GRID_HEIGHT - 1, y + brushSettings.brushSize); yDots++) {
        for (let xDots = Math.max(0, x - brushSettings.brushSize); xDots <= Math.min(GRID_WIDTH - 1, x + brushSettings.brushSize); xDots++) {
          const distance = Math.sqrt((xDots - x) ** 2 + (yDots - y) ** 2);
          if (distance <= brushSettings.brushSize) {
            updateData({x: xDots, y: yDots, cellType: currentTool}, data);
          }
        }
      }
    }
  }

  return data;
}


function updateTextureFromGrid() {
  // localGridModels.forEach((grid, i) => {
  //     // Dispose old?
  //   const texture = dataTextures[i] as THREE.DataTexture;
  //   if (!texture) {
  //     console.error("No texture!");
  //     debugger;
  //       return;
  //   }
  //   const data = texture.image.data;
  //   const size = texture.image.height * texture.image.width;
  //   const newData = gridToTexture(grid);
  //   // renderer.copyTextureToTexture(new THREE.Vector2(0, 0), new THREE.DataTexture(newData, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat), texture);
  //   // texture.needsUpdate = true;
  // });
  // Convert data to texture and update
  //   material.map = new THREE.DataTexture(data, GRID_WIDTH, GRID_HEIGHT, THREE.RGBAFormat);
  // material.map
  //   material.needsUpdate = true;
  // latestDataTexture.image = new ImageData(gridToTexture(latestGridModel), GRID_WIDTH, GRID_HEIGHT);
  // // latestDataTexture.image = new ImageData(gridToTexture(latestGridModel), GRID_WIDTH, GRID_HEIGHT);
  // latestDataTexture.needsUpdate = true;

  if (latestGridModel === null) {
    console.log("No grid model");
    return;
  }
  dataTexture.image = new ImageData(gridToTexture(latestGridModel), GRID_WIDTH, GRID_HEIGHT);
  dataTexture.needsUpdate = true;
}

// updateDataTexture( dataTexture );
// // perform copy from src to dest texture to a random position
// renderer.copyTextureToTexture( position, dataTexture, diffuseMap );


// function updateDataTexture( texture ) {
//
//   const size = texture.image.width * texture.image.height;
//   const data = texture.image.data;
//
//   // generate a random color and update texture data
//
//   color.setHex( Math.random() * 0xffffff );
//
//   const r = Math.floor( color.r * 255 );
//   const g = Math.floor( color.g * 255 );
//   const b = Math.floor( color.b * 255 );
//
//   for ( let i = 0; i < size; i ++ ) {
//
//     const stride = i * 4;
//
//     data[ stride ] = r;
//     data[ stride + 1 ] = g;
//     data[ stride + 2 ] = b;
//     data[ stride + 3 ] = 1;
//
//   }
//
// }

function renderLoop() {
  // stats.begin();
  // console.log("DTF" + drawingsThisFrame);
  drawingsThisFrame = 0;
  // lilStats.frameCount++;
  clientFrame++;  // Increment client frame number each time we render

  updateTextureFromGrid();

  renderer.render(scene, camera);
  // stats.end();
  requestAnimationFrame(renderLoop);
}

renderLoop();


function newEmptyGrid() {
  return new Uint8Array(GRID_WIDTH * GRID_HEIGHT).fill(-1);
  // return new Array(GRID_HEIGHT).fill(0).map(() => new Array(GRID_WIDTH).fill(-1));
}

function dispatchDrawChanges() {
  if (pendingUpdates.length > 0) {
    // Construct a grid with -1 for each cell that has NOT been updated
    const grid = newEmptyGrid();
    // console.log(`Dispatching ${pendingUpdates.length} updates`)
    for (const update of pendingUpdates) {
      if (update.state === PendingUpdateState.DISPATCHED) {
        continue;
      }
      const {x, y} = update.update;
      update.state = PendingUpdateState.DISPATCHED;
      grid[y * GRID_WIDTH + x] = update.update.cellType;
    }
    // let input = flattenGrid(grid);
    let inputBuffer = new Uint8Array(grid);
    // If we want to interleave the step server-side, pass here
    // let inputBuffer = new Uint8Array(input.concat(gridStep));
    let compressedGrid = encode(inputBuffer, inputBuffer.length);

    if (compressedGrid.length < 50000) {
      socket.send(compressedGrid);
    } else {
      console.error("Message too large to send!");
    }
  }
  setTimeout(dispatchDrawChanges, 1000 / (SERVER_GRID_UPDATE_FREQUENCY));
}
dispatchDrawChanges();

function numberToBytes(number) {
  // you can use constant number of bytes by using 8 or 4
  const len = Math.ceil(Math.log2(number) / 8);
  const byteArray = new Uint8Array(len);

  for (let index = 0; index < byteArray.length; index++) {
    const byte = number & 0xff;
    byteArray[index] = byte;
    number = (number - byte) / 256;
  }

  return byteArray;
}

function bytesToNumber(byteArray) {
  let result = 0;
  for (let i = byteArray.length - 1; i >= 0; i--) {
    result = (result * 256) + byteArray[i];
  }

  return result;
}


// Check for next update based on RequestAnimationFrame

let lastFrameTime: number = Date.now();
function updateLoop() {
  // simulate the next state if we have no pending updates
  if (pendingUpdates.length === 0) {
    // divide PREDICT_WITHIN_FRAME by how many animation frames we're getting per interval

    // const updatesToRun = Math.floor((Date.now() - lastFrameTime) / (1000 / (SERVER_GRID_UPDATE_FREQUENCY * PREDICT_MULTIPLE)));

    // for (let i = 0; i < updatesToRun; i++) {
      updateGrid(latestGridModel);
    // }

    lastFrameTime = Date.now();
    requestAnimationFrame(updateLoop);
  }

  // Set a timeout for the next update
}

async function initUpdateAndStart() {
  requestAnimationFrame(updateLoop);
}

initUpdateAndStart();