// import * as Matter from 'matter-js';
// const { Engine, Render, World, Bodies } = Matter;

function logMessage(message) {
    console.log(message);
}


export const GRID_WIDTH = 1024;
export const GRID_HEIGHT = 1024;

// export const engine = Engine.create();
// const world = engine.world;

const bodies = []; // Array to hold the bodies



export function gridFlatTo2D(flatGrid: Grid1D, width: number = GRID_WIDTH, height: number = GRID_HEIGHT) {
    // const grid = [];
    // for (let y = 0; y < height; y++) {
    //     grid.push(flatGrid.slice(y * width, (y + 1) * width));
    // }
    // Do imperative version for performance
    const grid = new Array(height);
    for (let y = 0; y < height; y++) {
        grid[y] = new Array(width);
        for (let x = 0; x < width; x++) {
            grid[y][x] = flatGrid[y * width + x];
        }
    }
    return grid;
}

export enum CellType {
    EMPTY = 0,
    WALL = 1,
    SAND = 2,
    WATER = 3,
    SAND_DENSITY_1 = 4,
    SAND_DENSITY_2 = 5,
    SAND_DENSITY_3 = 6,
    SAND_DENSITY_4 = 7,
    SAND_DENSITY_5 = 8,

    NIL = 255,
}

const cellColors: Record<CellType, number> = {
    [CellType.EMPTY]: 0xAAAAAA,
    [CellType.SAND]: 0xFFFF00,
    [CellType.WALL]: 0x000000,
    [CellType.WATER]: 0x0000FF,
    [CellType.SAND_DENSITY_1]: 0x00FF00,
    [CellType.SAND_DENSITY_2]: 0x00FFAA,
    [CellType.SAND_DENSITY_3]: 0x00FFFF,
    [CellType.SAND_DENSITY_4]: 0xAAFF00,
    [CellType.SAND_DENSITY_5]: 0xFF00FF,
};

export function getCellColor(cellType: CellType): number {
    return cellColors[cellType] ?? 0xAAAAAA;
}


export const EMPTY = CellType.EMPTY;
export const SAND = CellType.SAND;
export const OBSTACLE = CellType.WALL;
export const WATER = CellType.WATER;

export const ALL_TYPES = [CellType.EMPTY, CellType.WALL, CellType.SAND, CellType.WATER];
export type Grid1D = Uint8ClampedArray;

export const cachedInitGrid = initGrid();

export function initGrid(): Grid1D {
    const grid = new Uint8ClampedArray(GRID_WIDTH * GRID_HEIGHT);
    // grid.fill(SAND);

    for (let x = 0; x < GRID_WIDTH; x++) {
        grid[(GRID_HEIGHT - 1) * GRID_WIDTH + x] = OBSTACLE;
    }

    for (let x = 1; x < GRID_WIDTH; x++) {
        grid[Math.floor(GRID_HEIGHT / 2) * GRID_WIDTH + x] = SAND;
        grid[(Math.floor(GRID_HEIGHT / 2) + 1) * GRID_WIDTH + x] = SAND;
    }

    for (let x = 0; x < GRID_WIDTH; x++) {
        grid[(Math.floor(GRID_HEIGHT / 2) - 1) * GRID_WIDTH + x] = OBSTACLE;
    }

    return grid;
}

export interface CellUpdate {
    x: number;
    y: number;
    cellType: CellType;
}

interface BatchUpdate {
    updates: CellUpdate[];
    frame: number;
}


export enum ClientToServerMessageType {
    BATCH_UPDATE = "batchUpdate",
}

export interface ClientToServerMessage {
    type: ClientToServerMessageType;
}

export interface BatchUpdateMessage extends ClientToServerMessage {
    type: ClientToServerMessageType.BATCH_UPDATE;
    data: BatchUpdate;
}

export type Grid2D = CellType[][]; // y then x
export type Grid1D = CellType[]; // modulo GRID_WIDTH


export function gridIsFull(grid: Grid1D) {
    return !grid.includes(EMPTY);
}

let localGridStep = 0;

export function updateGrid(gridToUpdate: Grid1D): Grid1D {
    // Do it in-place
    const sourceGrid = gridToUpdate.slice(); // re-use from last round? only deltas?
    const newGrid = gridToUpdate;
    // if (localGridStep === 20) {
    //   // console.log("It is 20")
    //   updateBodiesFromGrid(gridToUpdate)
    // }
    // Optimization idea: https://blog.cloudflare.com/webgpu-in-workers/

    localGridStep++;
    // if (gridIsFull(gridToUpdate)) {
    //   return initGrid();
    // }

    // TODO: reuse? https://github.com/mikolalysenko/typedarray-pool

    const stepMod4 = localGridStep % 4;
    const yStart = stepMod4 === 0 || stepMod4 === 3 ? 0 : GRID_HEIGHT - 1;
    const yEnd = stepMod4 === 0 || stepMod4 === 3 ? GRID_HEIGHT : -1;
    const yStep = stepMod4 === 0 || stepMod4 === 3 ? 1 : -1;
    const xStart = stepMod4 === 0 || stepMod4 === 1 ? 0 : GRID_WIDTH - 1;
    const xEnd = stepMod4 === 0 || stepMod4 === 1 ? GRID_WIDTH : -1;
    const xStep = stepMod4 === 0 || stepMod4 === 1 ? 1 : -1;

    for (let y = yStart; y !== yEnd && y >= 0 && y < GRID_HEIGHT; y += yStep) {
        for (let x = xStart; x !== xEnd && x >= 0 && x < GRID_WIDTH; x += xStep) {
            const index = y * GRID_WIDTH + x;
            if (sourceGrid[index] !== EMPTY && sourceGrid[index] !== OBSTACLE) { // RARE SAND: replace tehse conditions with newGrid
                // Less dense sand can slip sideways over more dense sand
                let a = sourceGrid[index] === CellType.SAND_DENSITY_1 && sourceGrid[index - 1] === EMPTY;
                let b = sourceGrid[index] === CellType.SAND_DENSITY_2 && sourceGrid[index - 1] === EMPTY && sourceGrid[index - 2] === EMPTY;
                let c = sourceGrid[index] === CellType.SAND_DENSITY_3 && sourceGrid[index - 1] === EMPTY && sourceGrid[index - 2] === EMPTY && sourceGrid[index - 3] === EMPTY;
                let d = sourceGrid[index] === CellType.SAND_DENSITY_4 && sourceGrid[index - 1] === EMPTY && sourceGrid[index - 2] === EMPTY && sourceGrid[index - 3] === EMPTY && sourceGrid[index - 4] === EMPTY;
                let e = sourceGrid[index] === CellType.SAND_DENSITY_5 && sourceGrid[index - 1] === EMPTY && sourceGrid[index - 2] === EMPTY && sourceGrid[index - 3] === EMPTY && sourceGrid[index - 4] === EMPTY && sourceGrid[index - 5] === EMPTY;
                // I have literally no idea what the "number of open above" thing is meant to do but it is interesting
                let canSlipSideways = sourceGrid[index] === WATER || a || b || c || d || e;

                // Let's think, we could maybe also do this with a "density" property on the sand, and then we could just do a for loop
                // Or we could think about how to support landslides based on the density of the sand and the slope of the sand and the slope of the sand below it and the pressure of the sand below and above it

                if (y + 1 < GRID_HEIGHT && newGrid[(y + 1) * GRID_WIDTH + x] === EMPTY) {
                    newGrid[(y + 1) * GRID_WIDTH + x] = sourceGrid[index];
                    newGrid[index] = EMPTY;
                } else if (y + 1 < GRID_HEIGHT && x > 0 && newGrid[(y + 1) * GRID_WIDTH + (x - 1)] === EMPTY && newGrid[y * GRID_WIDTH + (x - 1)] !== OBSTACLE) {
                    newGrid[(y + 1) * GRID_WIDTH + (x - 1)] = sourceGrid[index];
                    newGrid[index] = EMPTY;
                } else if (y + 1 < GRID_HEIGHT && x < GRID_WIDTH - 1 && newGrid[(y + 1) * GRID_WIDTH + (x + 1)] === EMPTY && newGrid[y * GRID_WIDTH + (x + 1)] !== OBSTACLE) {
                    newGrid[(y + 1) * GRID_WIDTH + (x + 1)] = sourceGrid[index];
                    newGrid[index] = EMPTY;
                } else if (canSlipSideways) {
                    if (x > 0 && newGrid[y * GRID_WIDTH + (x - 1)] === EMPTY) {
                        newGrid[y * GRID_WIDTH + (x - 1)] = sourceGrid[index];
                        newGrid[index] = EMPTY;
                    } else if (x < GRID_WIDTH - 1 && newGrid[y * GRID_WIDTH + (x + 1)] === EMPTY) {
                        newGrid[y * GRID_WIDTH + (x + 1)] = sourceGrid[index];
                        newGrid[index] = EMPTY;
                    }
                }
            }

        }
    }

    // This trick does a second pass to allow fine materials to fall through coarse materials (e.g. sand through water)
    // RARE SAND DISCOVERED: this creates lines of motility, stunning blots of sand, tree shading shapes quickly
    //      Could be a mode for coloring in holes. Could amke it content-aware somehow
    // Problem: moving upwards instead of downwards -
    //      RARE SAND DISCOVERED: downwards also cool (spreads out only while falling down - almost creature-y then plop into sand)

    // for (let y = GRID_HEIGHT - 1; y >= 1; y--) {
    //     for (let x = 0; x < GRID_WIDTH; x++) {
    //         const index = y * GRID_WIDTH + x;
    //         if (sourceGrid[index] !== EMPTY && sourceGrid[index] !== OBSTACLE) {
    //             if (newGrid[index + GRID_WIDTH] === EMPTY) {
    //                 newGrid[index + GRID_WIDTH] = sourceGrid[index];
    //                 newGrid[index] = EMPTY;
    //             }
    //         }
    //     }
    // }
}

interface Gaussian {
    meanX: number;
    meanY: number;
    varianceX: number;
    varianceY: number;
    amplitude: number;
}

function gaussian(x: number, y: number, gaussian: Gaussian): number {
    const {meanX, meanY, varianceX, varianceY, amplitude} = gaussian;
    const exponent = -((Math.pow(x - meanX, 2) / (2 * varianceX)) + (Math.pow(y - meanY, 2) / (2 * varianceY)));
    return amplitude * Math.exp(exponent);
}

function combineGaussians(gaussians: Gaussian[], width: number, height: number): number[] {
    const densityField = new Array(width * height).fill(0);
    for (let i = 0; i < densityField.length; i++) {
        const y = Math.floor(i / width);
        const x = i % width;
        densityField[i] = gaussians.reduce((sum, g) => sum + gaussian(x, y, g), 0);
    }
    return densityField;
}


const SAND_THRESHOLD = 0.5;  // Threshold for considering a cell as sand
function updateGridWithDensityField(densityField: number[], grid: Grid1D): void {
    for (let i = 0; i < densityField.length; i++) {
        grid[i] = densityField[i] > SAND_THRESHOLD ? SAND : grid[i];
    }
}


function updateBodiesFromGrid(grid) {
    // World.clear(world); // Clear the world of existing bodies
    //
    // let currentType = null;
    // let startX = 0;
    // let startY = 0;
    // let width = 1;
    // let height = 1;
    // let skipCount = 0;
    //
    // for (let i = 0; i < grid.length; i++) {
    //     const x = i % GRID_WIDTH;
    //     const y = Math.floor(i / GRID_WIDTH);
    //     const type = grid[i];
    //
    //     if (type !== currentType) {
    //         if (currentType !== null && skipCount < 3) {
    //             // Add the previous body to the world
    //             const body = Bodies.rectangle(startX + width / 2, startY + height / 2, width, height, {
    //                 isStatic: currentType === OBSTACLE,
    //                 render: {
    //                     fillStyle: getColorForType(currentType)
    //                 }
    //             });
    //             World.add(world, body);
    //             bodies.push(body);
    //         }
    //
    //         // Start a new body
    //         startX = x;
    //         startY = y;
    //         width = 1;
    //         height = 1;
    //         currentType = type;
    //         skipCount = 0;
    //     } else {
    //         // Extend the current body
    //         if (y === startY) {
    //             width += 1;
    //         } else {
    //             height += 1;
    //         }
    //         skipCount = 0;
    //     }
    // }
    //
    // if (currentType !== null && skipCount < 3) {
    //     // Add the last body to the world
    //     const body = Bodies.rectangle(startX + width / 2, startY + height / 2, width, height, {
    //         isStatic: currentType === OBSTACLE,
    //         render: {
    //             fillStyle: getColorForType(currentType)
    //         }
    //     });
    //     bodies.push(body);
    //     World.add(world, body);
    // }
}

export function getGridStep() {
    return localGridStep;
}

export enum MouseActionType {
    CLICK = 'click',
    DRAG = 'drag',
    RELEASE = 'release',
}

export interface DragMouseActionMessage extends BrushActionMessage {
    actionType: MouseActionType.DRAG;
    lastX: number;
    lastY: number;
}

export interface BrushActionMessage extends MouseActionMessage {
    actionType: MouseActionType.DRAG;
    frame: number;
}

export interface ClickActionMessage extends MouseActionMessage {
    actionType: MouseActionType.CLICK;
    frame: number;
}

export interface MouseActionMessage {
    actionType: MouseActionType;
    x: number;
    y: number;
    cellType: CellType;
    brushSize: number;
}

export function drawLine(grid: Grid1D, x1: number, y1: number, x2: number, y2: number, radius: number, cellType: CellType) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const steps = Math.max(Math.abs(dx), Math.abs(dy));
    const deltaX = dx / steps;
    const deltaY = dy / steps;
    let x = x1, y = y1;

    for (let i = 0; i <= steps; i++) {
        drawCircle(grid, Math.round(x), Math.round(y), radius, cellType);
        x += deltaX;
        y += deltaY;
    }
}

export function drawCircle(grid: Grid1D, cx: number, cy: number, radius: number, cellType: CellType) {
    if (radius === 1) {
        grid[cy * GRID_WIDTH + cx] = cellType;
        return;
    }
    let airCount = 0;

    for (let y = Math.max(0, cy - radius); y <= Math.min(GRID_HEIGHT - 1, cy + radius); y++) {
        for (let x = Math.max(0, cx - radius); x <= Math.min(GRID_WIDTH - 1, cx + radius); x++) {
            const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
            if (distance <= radius) {
                const index = y * GRID_WIDTH + x;
                if (grid[index] === OBSTACLE && cellType === SAND) {
                    continue;
                }
                // If it's air, use a pattern of air and whatever is below it
                // if (grid[index] === CellType.EMPTY) {
                //     // Flip
                //     if (airCount % 2 === 0) {
                //         grid[index] = CellType.EMPTY;
                //     } else {
                //         grid[index] = cellType;
                //     }
                //     airCount = (airCount + 1) % 2;
                // } else {
                    grid[index] = cellType;
                // }
            }
        }
    }
}

// export const SERVER_GRID_UPDATE_FREQUENCY = 20;
// export const PREDICT_MULTIPLE = 4;
export const SERVER_GRID_UPDATE_FREQUENCY = 15;
export const PREDICT_MULTIPLE = 150;
export const SERVER_PREDICT_WITHIN_FRAME = 10;


